<template>
  <div v-if="isLoading" class="d-flex flex-column justify-content-center">
    <hs-loading />
  </div>
  <section class="content-editor" v-else>
    <hsPageHeader
      :title="title"
      :back-text="$t(`sparkmembers.coupons.views.list.components.page-header.back-button-label`)"
      back-route="ProductDashboard"
    >
      <template slot="actions">
        <hs-button class="text-decoration-none text-dark" variant="link" @click.stop="showWebinar()">
          <hs-icon class="font-size-lg" variant="light" icon="eye" />
          {{
            $t(`sparkmembers.contents.views.editor.components.webinar.components.page-header.show-lesson-button-label`)
          }}
        </hs-button>
      </template>
    </hsPageHeader>
    <section class="container-fluid">
      <div class="row mt-5 px-4">
        <div class="col-xl-9 rounded-sm">
          <section class="bg-white p-4 mb-5 rounded-sm">
            <webinar-herospark
              @createDefinitionParams="updateDescription"
              @updatedUrl="updateOriginUrl"
              @updatedUrlChat="updateChatUrl"
              :webinar_description="description"
              :origin_url="origin_url"
              :chat_url="chat_url"
            />
            <div class="d-flex justify-content-end">
              <hs-button variant="primary" @click="onSave">{{
                $t(`sparkmembers.contents.views.editor.components.webinar.index.save-button-label`)
              }}</hs-button>
            </div>
          </section>

          <AdditionalMedias
            :type="type"
            :additionalMedias="additionalMedias"
            @add="additionalMediaAdded"
            @change="additionalMediaChanged"
            @remove="additionalMediaRemoved"
            data-track="lesson extra content"
            @move="additionalMediaMoved"
            @premium="onPremiumFeature"
          />

          <Automations
            id="automations"
            :type="type"
            data-track="lesson automation"
            :automations="automations"
            @save="automationSaved"
            @change="automationChanged"
            @remove="automationRemoved"
            @premium="onPremiumFeature"
          />
        </div>

        <div class="col-xl-3">
          <div class="d-flex flex-column">
            <VisibilityCard v-model="content.available" @input="onEditLesson()" />
            <CommentsCard v-model="commentsActivated" :type="type" @input="onEditLesson()" />
            <LessonReleaseLimitationCard
              v-model="lesson.schedule"
              :id="this.selectedSchool.id"
              :email="this.selectedSchool.email"
              @input="onEditLesson()"
            />
          </div>
        </div>
      </div>
    </section>
    <hsPremiumModal id="premium-modal" @click="requestPremiumFeature" />
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import WebinarHerospark from '@/components/WebinarHerospark';
import hsPageHeader from '@/components/_structures/PageHeader';
import toastHelper from '@/shared/helpers/toast';
import courseService from '@/sparkmembers/services/course';
import mediaService from '@/sparkmembers/services/media';
import lessonService from '@/sparkmembers/services/lesson';
import { productService } from '@/services';
import { hsLoading, hsPremiumModal } from '@/components';
import VisibilityCard from '../Additional/components/VisibilityCard.vue';
import CommentsCard from '../CommentsCard';
import LessonReleaseLimitationCard from '../Lesson/components/LessonReleaseLimitationCard';
import AdditionalMedias from '../AdditionalMedias.vue';
import Automations from '../Automations.vue';
import AutomationMixin from '@/sparkmembers/views/Contents/mixins/AutomationMixin';
import { getAdminViewSsoUrl } from '@/shared/helpers/general';

export default {
  name: 'Webinar',
  mixins: [AutomationMixin],
  components: {
    WebinarHerospark,
    hsPageHeader,
    hsLoading,
    hsPremiumModal,
    VisibilityCard,
    LessonReleaseLimitationCard,
    CommentsCard,
    AdditionalMedias,
    Automations,
  },
  data() {
    return {
      type: 'webinar',
      isLoading: false,
      description: '',
      chat_url: '',
      origin_url: '',
      title: '',
      content: {},
      media: {},
      medias: [],
      lesson: {},
      commentsActivated: null,
    };
  },
  computed: {
    ...mapGetters('school', ['getDefaultDomain']),
    ...mapState({
      product: state => state.product.selectedProduct,
      course: state => state.course.selectedCourse,
      loggedUser: state => state.auth.loggedUser,
      selectedSchool: state => state.school.selectedSchool,
    }),
    additionalMedias() {
      return this.media ? this.medias.filter(m => m.id !== this.media.id) : this.medias;
    },
  },
  methods: {
    async loadData() {
      try {
        this.isLoading = true;
        const { data: content } = await courseService.getCourseContent(this.$route.params.contentId);
        this.content = content;
        const {
          title,
          description,
          media,
          release_at,
          release_after,
          lock_at,
          lock_after,
          section_id,
          contents,
        } = content.lesson;
        this.commentsActivated = section_id !== null;
        this.lesson = content.lesson;
        this.lesson.schedule = {
          release_at,
          release_after,
          lock_at,
          lock_after,
        };
        if (contents) {
          const content_ids = contents.map(c => c.content.id);
          this.medias = (await mediaService.getAll(content_ids)).map(r => r.data);
        }
        this.title = title;
        this.description = description;
        this.media = (await mediaService.get(media.id)).data;
        this.origin_url = media.origin_url;
        this.chat_url = media.chat_url;
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(`sparkmembers.contents.views.editor.components.webinar.index.toast-messages.content-loading-error`)
        );
        this.$router.push({ name: 'ProductDashboard' });
      } finally {
        this.isLoading = false;
      }
    },
    async onEditLesson() {
      try {
        this.isLoading = true;
        let lesson = { ...this.lesson, ...this.lesson.schedule };
        if (this.commentsActivated) {
          const { section } = await lessonService.addCommentsInLesson({
            title: lesson.title,
            description: lesson.description,
            school_product_id: this.product.id,
            lesson_id: lesson.id,
          });
          lesson.section_id = section.id;
          this.lesson.section_id = section.id;
        } else {
          lesson.section_id = null;
          this.lesson.section_id = null;
        }

        await courseService.updateCourseContent(this.content);
        await lessonService.update(lesson);
        toastHelper.successMessage(
          this.$t('sparkmembers.contents.views.editor.components.webinar.index.toast-messages.details-update-success')
        );
      } catch (error) {
        toastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.webinar.index.toast-messages.details-update-error')
        );
      } finally {
        this.isLoading = false;
      }
    },
    async onSave() {
      this.isLoading = true;
      try {
        await productService.update({
          id: this.$route.params.id,
          description: this.description,
        });
        await lessonService.update({
          id: this.lesson.id,
          description: this.description,
        });

        await mediaService.update({
          id: this.media.id,
          origin_url: this.origin_url,
          chat_url: this.chat_url,
          description: this.description,
        });

        toastHelper.successMessage(
          this.$t(`sparkmembers.contents.views.editor.components.webinar.index.toast-messages.details-update-success`)
        );
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(`sparkmembers.contents.views.editor.components.webinar.index.toast-messages.details-update-error`)
        );
      } finally {
        this.isLoading = false;
      }
    },
    showWebinar() {
      window.open(
        getAdminViewSsoUrl({
          domain: this.getDefaultDomain,
          user: this.loggedUser,
          redirectTo: `/admin_view/courses/${this.course.id}/course_contents/${this.$route.params.contentId}`,
        }),
        '_blank'
      );
    },
    updateDescription(description) {
      this.description = description;
    },
    updateOriginUrl(origin_url) {
      this.origin_url = origin_url;
    },
    updateChatUrl(chat_url) {
      this.chat_url = chat_url;
    },
    async onPremiumFeature(feature) {
      this.premiumFeatureLocation = `lesson ${feature.toLowerCase()}`;
      this.$bvModal.show('premium-modal');
      analyticsService.track({
        event: 'Paywall displayed',
        props: {
          location: this.premiumFeatureLocation,
        },
      });
      const school = this.selectedSchool;
      school.extra_settings.show_top_bar = 'true';
      await this.updateSchool({ id: school.id, extra_settings: school.extra_settings });
    },
    requestPremiumFeature() {
      this.$router.push({ name: 'MyAccountPlans' });
      this.$bvModal.hide('premium-modal');
    },
    async additionalMediaAdded(attrs) {
      this.isLoading = true;

      try {
        const newAdditionalMedia = (await mediaService.create({ media: { ...attrs } })).data;
        const content_ids = this.medias.map(m => m.id);

        content_ids.push(newAdditionalMedia.id);

        await lessonService.update({
          id: this.lesson.id,
          content_ids,
        });

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-add.success`
          )
        );

        this.medias.push(newAdditionalMedia);
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${this.type}.index.toast-messages.additional-media-add.error`
          )
        );
      }

      this.isLoading = false;
    },
    async additionalMediaChanged(attrs) {
      try {
        await mediaService.update(attrs);

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-update.success`
          )
        );
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-update.error`
          )
        );
      }
    },
    async additionalMediaRemoved(id) {
      this.isLoading = true;

      try {
        let content_ids = this.medias.map(m => m.id);
        content_ids = content_ids.filter(cid => cid !== id);

        await lessonService.update({
          id: this.lesson.id,
          content_ids,
        });

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-remove.success`
          )
        );

        this.medias = this.medias.filter(m => m.id !== id);
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-remove.error`
          )
        );
      }

      this.isLoading = false;
    },
    async additionalMediaMoved(additionalMedias) {
      const content_ids = additionalMedias.map(am => am.id);
      if (this.media) content_ids.unshift(this.media.id);

      try {
        await lessonService.update({
          id: this.lesson.id,
          content_ids,
        });

        this.medias = [...additionalMedias];
        if (this.media) this.medias.unshift(this.media);

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-move.success`
          )
        );
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-move.error`
          )
        );
      }
    },
  },
  created() {
    this.loadData();
  },
};
</script>
